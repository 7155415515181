import React, {FC, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import Section from '../../components/section';
import {useMutation} from '@apollo/react-hooks';
import {UserVerifyComponentProps} from '../../types';
import {SUBSCRIBE_VERIFY} from '../../graphql/mutations';
import {Translate} from '../../components/translations';
import {setUserToken, setUserHash} from '../../helpers';
import {navigate} from 'gatsby';
import {verified} from '../../images/svgIcons';
import Button from '../../components/button';
import SimpleLayout from '../../components/layout_simple';

const PrivacyPolicy: FC<UserVerifyComponentProps> = ({token}) => {
  const [verifyMutation, {data}] = useMutation(SUBSCRIBE_VERIFY);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    token && token !== 'index.html' && verifyMutation({variables: {verifyCode: token}});
  }, []);

  useEffect(() => {
    setSuccess(false);
    setErrorMessage('');
    if (data && data.WatchSubscribeOps && data.WatchSubscribeOps.verify) {
      const {error, message} = data.WatchSubscribeOps.verify;
      if (error) {
        setErrorMessage(message);
      } else {
        setSuccess(true);
      }
    }
  }, [data]);
  const searchQuery =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.search &&
    global.window.location.search.split('&');
  let successRegistration = '';
  let error = '';
  let user_token = '';
  let user_hash = '';
  searchQuery &&
    searchQuery.find((item: any) => {
      if (item.indexOf('token') > -1) {
        user_token = item.slice(item.indexOf('=') + 1, item.length);
        user_token && setUserToken(user_token);
      }
      if (item.indexOf('hash') > -1) {
        user_hash = item.slice(item.indexOf('=') + 1, item.length);
        user_hash && setUserHash(user_hash);
      }
      if (item.indexOf('success') > -1) {
        successRegistration = item.slice(item.indexOf('=') + 1, item.length);
      }
      if (item.indexOf('error') > -1) {
        error = item.slice(item.indexOf('=') + 1, item.length);
      }
    });
  useEffect(() => {
    if (successRegistration) {
      setUserToken('');
      setTimeout(() => {
       navigate('/login/');
      }, 3000);
    } else if (success) {
      setTimeout(() => {
        navigate('/login/');
      }, 3000);
    }
  }, [success, successRegistration]);

  const onClick = () => {
    window.location.replace('https://dash.hexomatic.com');
  };

  return (
    <div className="content-wrapper">
      <SimpleLayout isClickable>
        <Section className='mb-5'>
          <div className="col-lg-12 text-center" style={{minHeight: '190px'}}>
            {errorMessage || error ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage || error} />
              </div>
            ) : success || successRegistration ? (
              <>
                <div>{verified}</div>
                <p className="my-3" style={{color: '#00042a', fontWeight: 600, fontSize: '24px'}}>
                  Successfully verified
                </p>
                <p className="mb-3" style={{color: '#516077', fontWeight: 400, fontSize: '18px'}}>
                  You will be automatically redirected to Login page in 3 seconds
                </p>
                <Button onClick={onClick}>Go to dashboard</Button>
              </>
            ) : (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </div>
        </Section>
      </SimpleLayout>
    </div>
  );
};

export default PrivacyPolicy;
